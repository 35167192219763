import React, { Fragment, useContext, useCallback, useEffect, useState, useRef } from "react"
import { RootContext } from "RootContext"

// @mui material components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
//import CircularProgress from "@mui/material/CircularProgress"

//pegaso components
import { fetchWithToken } from "components/Fetch/api-fetch"
import UserUtils from "components/Utils/UserUtils"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import CompanyUtils from "components/Utils/CompanyUtils"
import CompanyScopes from "views/CompanyScopes/CompanyScopes"
import ErrorUtils from "components/Tools/ErrorUtils"
import References from "views/References/References"
import StringUtils from "components/Tools/StringUtils"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
//import { makeStyles } from '@mui/styles';

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allCategories,
    allCompanyScopes,
    allUserScopes,
    creation,
    handleClickClose,
    setAdminTableRows
  } = props

  const {
    apiUrl,
    t,
    getUsername,
    access_token,
    refresh_token,
    setAccess_token
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  const [companyDeposits, setCompanyDeposits] = useState([])
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const languageList = UserUtils.languageList
  const abortController = useRef(null)


  //Valori dei Dati
  const [company, setCompany] = useState(
    creation
      ? {
        companyId: CompanyUtils.countCompanyId(adminTableRows),
        companyJobd: "",
        name: undefined,
        urlAccessCode: "",
        active: adminTableRowData ? adminTableRowData.active : true,
        connection: adminTableRowData ? adminTableRowData.connection : "AS400_CONNECTION_PEG",
        defaultLanguage: adminTableRowData ? adminTableRowData.defaultLanguage : "it-it"
      }
      : {
        companyId: adminTableRowData.companyId,
        companyJobd: adminTableRowData.companyJobd,
        name: adminTableRowData.name,
        urlAccessCode: adminTableRowData.urlAccessCode,
        active: adminTableRowData.active,
        connection: adminTableRowData.connection,
        defaultLanguage: adminTableRowData.defaultLanguage,
      }
  )

  const [address, setAddress] = useState(
    creation && !adminTableRowData ? {
      street: "",
      number: "",
      city: "",
      province: "",
      state: "",
      zipCode: 0,
    } : adminTableRowData.address
  )
  const [companyScopes, setCompanyScopes] = useState(creation && !adminTableRowData ? [] : adminTableRowData.purchased.companyScopes)
  const [footer, setFooter] = useState(creation && !adminTableRowData ?
    {
      site: "",
      telephoneNumber: "",
      email: "",
      mobilePhone: "",
    } : adminTableRowData.footer
  )
  const [userScopes, setUserScopes] = useState(creation && !adminTableRowData ? [] : adminTableRowData.purchased.userScopes)
  const [references, setReferences] = useState(creation && !adminTableRowData ? [] : adminTableRowData.references)

  const [diameterRanges, setDiameterRanges] = useState(creation && !adminTableRowData ?
    {
      diameter: {
        min: 50,
        max: 99
      },
      ellipse: {
        min: 50,
        max: 99
      }
    } :
    {
      diameter: {
        min: 50,
        max: 99
      },
      ellipse: {
        min: 50,
        max: 99
      }
    } //adminTableRowData.diameterRanges
  )

  const [tollerances, setTollerances] = useState(creation && !adminTableRowData ?
    {
      sv: 2,
      bi: 4,
      pr: 4
    } : {
      sv: 2,
      bi: 4,
      pr: 4
    } //adminTableRowData.tollerances
  )
  const [tariffs, setTariffs] = useState(creation && !adminTableRowData ?
    {
      install: {
        yesRemoteEdging: 100,
        noRemoteEdging: 50
      },
      support: {
        yesRemoteEdging: 10,
        noRemoteEdging: 5
      }
    } : {
      install: {
        yesRemoteEdging: 100,
        noRemoteEdging: 50
      },
      support: {
        yesRemoteEdging: 10,
        noRemoteEdging: 5
      }

    } //adminTableRowData.tariffs
  )


  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/
  //Cambia field
  const handleChange = (name) => (event) => {
    setCompany({ ...company, [name]: event.target.value })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setCompany({ ...company, [name]: event.target.checked })
  }

  //Cambia Company.Address field
  const handleChangeAddress = (name) => (event) => {
    setAddress({
      ...address,
      [name]: event.target.value,
    })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrimAddress = (addressParameter, name) => (event) => {
    setAddress({
      ...address,
      [name]: addressParameter.trim(),
    })
  }

  //Cambia Range diametri
  const handleChangeDiameterRages = (field, name) => (event) => {
    if (field === "diameter") {
      setDiameterRanges({
        ...diameterRanges,
        diameter: { ...diameterRanges.diameter, [name]: event.target.value }
      })
    }
    else if (field === "ellipse") {
      setDiameterRanges({
        ...diameterRanges,
        ellipse: { ...diameterRanges.ellipse, [name]: event.target.value }
      })
    }
  }

  //Cambia tolleranze
  const handleChangeTollerances = (name) => (event) => {
    setTollerances({
      ...tollerances,
      [name]: event.target.value,
    })
  }

  //Cambia tariffe
  const handleChangeTariffs = (field, name) => (event) => {
    if (field === "install") {
      setTariffs({
        ...tariffs,
        install: { ...tariffs.install, [name]: event.target.value }
      })
    }
    else if (field === "support") {
      setTariffs({
        ...tariffs,
        support: { ...tariffs.support, [name]: event.target.value }
      })
    }
  }
  //Cambia footer
  const handleChangeFooter = (name) => (event) => {
    setFooter({
      ...footer,
      [name]: event.target.value,
    })
  }

  //Salva 
  const handleSave = useCallback(
    () => {
      abortController.current = new AbortController()
      const url = creation ? `${apiUrl}/Company/add` : `${apiUrl}/Company/update`

      fetchWithToken(url, {
        method: creation ? "POST" : "PUT",
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
        body: JSON.stringify({
          companyId: parseInt(company.companyId),
          companyJobd: company.companyJobd,
          connection: company.connection,
          name: company.name,
          urlAccessCode: company.urlAccessCode,
          active: company.active,
          address: address,
          references: references,
          footer: footer,
          defaultLanguage: company.defaultLanguage,
          //diameterRanges: diameterRanges,
          //tollerances: tollerances,
          //tariffs: tariffs,
          purchased: {
            companyScopes: companyScopes,
            userScopes: userScopes,
          },
        })
      })
        .then((response) => { 
          return response.body 
        })
        .then((data) => {
          if(data){
            var newUrlAccessCode = data.urlAccessCode
            let msgDetails = StringUtils.generateNewLines(
              `${t("10119")}\n\n CompanyId: ${company.companyId}\n Name: ${company.name}\n UrlAccessCode: ${newUrlAccessCode}`
            )
            setOpenDialog(true)
            setIsSaving(false)
            setDialogMsg(msgDetails)
  
            // Aggiorna la lista
            let newElem = {
              companyId: parseInt(company.companyId),
              companyJobd: company.companyJobd,
              name: company.name,
              active: company.active,
              urlAccessCode: newUrlAccessCode,
              created: creation ? {
                user: getUsername(),
                date: new Date().toISOString()
              } : adminTableRowData.created,
              modified: {
                user: getUsername(),
                date: new Date().toISOString()
              }
            }
            let newAdminTableRows = creation
              ? adminTableRows.concat(newElem)
              : adminTableRows.map((elem) => {
                if (parseInt(elem.companyId) === parseInt(company.companyId)) {
                  return newElem
                } else {
                  return elem
                }
              })
            setAdminTableRows(newAdminTableRows)
          }else{
            ErrorUtils.errorLog(`${url} => handleSave`, err)
            setDialogMsg(t("22"))
            setOpenDialog(true)
            setIsSaving(false)
          }
        })
        .catch((err) => {
          ErrorUtils.errorLog(`${url} => handleSave`, err)
          setDialogMsg(t("22"))
          setOpenDialog(true)
          setIsSaving(false)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token, company,
      diameterRanges, references, footer, tollerances, tariffs, companyScopes, userScopes, address
    ]
  )

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  function disableSave() {
    return !company.companyJobd === undefined ||
      (creation && !!adminTableRows.find(item => item.companyJobd === company.companyJobd))
  }

  const fetchCompanyDeposits = useCallback(
    () => {
      abortController.current = new AbortController()
      if (company.urlAccessCode) {
        const url = `${apiUrl}/Deposit/get-all?urlAccessCode=${company.urlAccessCode}`
        fetchWithToken(url, {
          method: "GET",
          apiUrl: apiUrl,
          access_token: access_token,
          refresh_token: refresh_token,
          setAccess_token: setAccess_token,
          signal: abortController.current.signal,
        })
          .then((response) => { return response.body })
          .then((data) => {
            setCompanyDeposits(data)
          })
          .catch((err) => {
            ErrorUtils.errorLog(`${url} => fetchCompanyDeposits`, err)
          })
      }
    },
    [access_token, apiUrl, refresh_token, setAccess_token]
  )

  /*************************************
  *
  * UseEffect
  *
  *************************************/
  useEffect(() => {
    if (!creation) {
      if (companyDeposits.length === 0) {
        fetchCompanyDeposits()
      }
    }
  }, [creation, companyDeposits, fetchCompanyDeposits])

  return (
    <Fragment>
      <Card>
        <CardContent>
          <p align="center">
            <u>{t("10120").toUpperCase()} </u>
          </p>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10104").toUpperCase()}</u>
                  </p>
                </Grid>

                {/*GRID CONTENENTE I DATI */}

                {/*DATI COMPANY*/}
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={company.active}
                        onChange={handleChangeChecked("active")}
                        name="active"
                      />
                    }
                    label={t("10047")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    required
                    id={"companyJobd"}
                    label={t("10115")}
                    value={company.companyJobd || ""}
                    margin="normal"
                    helperText={!creation ? t("10105") : ""}
                    onChange={handleChange("companyJobd")}
                    className={classesText.textField}
                    InputProps={{
                      readOnly: !creation,
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    required
                    id={"name"}
                    label={t("10117")}
                    value={company.name || ""}
                    margin="normal"
                    onChange={handleChange("name")}
                    className={classesText.textField}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    disabled
                    id={"urlAccessCode"}
                    label={t("10116")}
                    helperText={t("10105")}
                    className={classesText.textField}
                    value={company.urlAccessCode || ""}
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/*CONNESSIONE  + DEFAULT LANGUAGE*/}
                <Grid item xs={2}>
                  <TextField
                    required
                    id={"connection"}
                    label={t("10140")}
                    onChange={handleChange("connection")}
                    helperText={t("10140")}
                    className={classesText.textField}
                    value={company.connection || ""}
                    margin="normal"
                    InputProps={{
                      readOnly: !creation,
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id="defaultLanguage"
                    select
                    label="defaultLanguage"
                    value={company.defaultLanguage}
                    SelectProps={{
                      native: true,
                    }}
                    onChange={handleChange("defaultLanguage")}
                    helperText="Please select your currency"
                  >
                    {languageList.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/*INDIRIZZO*/}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10163").toUpperCase()}</u>
                        </p>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <TextField
                            id={"street"}
                            label={t("10121")}
                            onChange={handleChangeAddress("street")}
                            onBlur={onBlurTrimAddress(address.street, "street")}
                            className={classesText.textField}
                            value={address.street || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            id={"number"}
                            label={t("10122")}
                            onChange={handleChangeAddress("number")}
                            onBlur={onBlurTrimAddress(address.number, "number")}
                            className={classesText.textField}
                            value={address.number || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            id={"city"}
                            label={t("10123")}
                            onChange={handleChangeAddress("city")}
                            onBlur={onBlurTrimAddress(address.city, "city")}
                            className={classesText.textField}
                            value={address.city || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            id={"province"}
                            label={t("10124")}
                            onChange={handleChangeAddress("province")}
                            onBlur={onBlurTrimAddress(address.province, "province")}
                            className={classesText.textField}
                            value={address.province || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            id={"zipCode"}
                            label={t("10126")}
                            onChange={handleChangeAddress("zipCode")}
                            onBlur={onBlurTrimAddress(address.zipCode, "zipCode")}
                            className={classesText.textField}
                            value={address.zipCode || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            id={"state"}
                            label={t("10125")}
                            onChange={handleChangeAddress("state")}
                            onBlur={onBlurTrimAddress(address.state, "state")}
                            className={classesText.textField}
                            value={address.state || ""}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/*RANGE VALORI*/}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel2a-header"
                    >
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10285").toUpperCase()}</u>
                        </p>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            id={"diaMin"}
                            label={t("10286")}
                            onChange={handleChangeDiameterRages("diameter", "min")}
                            className={classesText.textField}
                            value={diameterRanges.diameter.min || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"diaMax"}
                            label={t("10287")}
                            onChange={handleChangeDiameterRages("diameter", "max")}
                            className={classesText.textField}
                            value={diameterRanges.diameter.max || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"ellMin"}
                            label={t("10288")}
                            onChange={handleChangeDiameterRages("ellipse", "min")}
                            className={classesText.textField}
                            value={diameterRanges.ellipse.min || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"ellMin"}
                            label={t("10289")}
                            onChange={handleChangeDiameterRages("ellipse", "max")}
                            className={classesText.textField}
                            value={diameterRanges.ellipse.max || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"tollSv"}
                            label={t("10290")}
                            onChange={handleChangeTollerances("sv")}
                            className={classesText.textField}
                            value={tollerances.sv || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"tollBi"}
                            label={t("10291")}
                            onChange={handleChangeTollerances("bi")}
                            className={classesText.textField}
                            value={tollerances.bi || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"tollPr"}
                            label={t("10292")}
                            onChange={handleChangeTollerances("pr")}
                            className={classesText.textField}
                            value={tollerances.pr || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3} />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/*TARIFFE*/}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel3a-header"
                    >
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10293").toUpperCase()}</u>
                        </p>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>

                        <Grid item xs={3}>
                          <TextField
                            id={"tar1"}
                            label={t("10294")}
                            onChange={handleChangeTariffs("install", "yesRemoteEdging")}
                            className={classesText.textField}
                            value={tariffs.install.yesRemoteEdging || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"tar2"}
                            label={t("10295")}
                            onChange={handleChangeTariffs("install", "noRemoteEdging")}
                            className={classesText.textField}
                            value={tariffs.install.noRemoteEdging || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"tar3"}
                            label={t("10296")}
                            onChange={handleChangeTariffs("support", "yesRemoteEdging")}
                            className={classesText.textField}
                            value={tariffs.support.yesRemoteEdging || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"tar4"}
                            label={t("10297")}
                            onChange={handleChangeTariffs("support", "noRemoteEdging")}
                            className={classesText.textField}
                            value={tariffs.support.noRemoteEdging || ""}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/*DATI FOOTER*/}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel4a-header"
                    >
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10221").toUpperCase()}</u>
                        </p>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            id={"foot1"}
                            label={t("10220")}
                            onChange={handleChangeFooter("site")}
                            className={classesText.textField}
                            value={footer.site || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"foot2"}
                            label={t("10108")}
                            onChange={handleChangeFooter("email")}
                            className={classesText.textField}
                            value={footer.email || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"foot3"}
                            label={t("10149")}
                            onChange={handleChangeFooter("telephone")}
                            className={classesText.textField}
                            value={footer.telephone || ""}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            id={"foot4"}
                            label={t("10181")}
                            onChange={handleChangeFooter("mobile")}
                            className={classesText.textField}
                            value={footer.mobile || ""}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/*CONTATTI*/}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel5a-header"
                    >
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10298").toUpperCase()}</u>
                        </p>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <References
                          references={references}
                          companyDeposits={companyDeposits}
                          setReferences={setReferences}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/*COMPANY SCOPE*/}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel6a-header"
                    >
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10299").toUpperCase()}</u>
                        </p>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <CompanyScopes
                          allCategories={allCategories}
                          allScopes={allCompanyScopes}
                          scopes={companyScopes}
                          setScopes={setCompanyScopes}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/*USER SCOPE*/}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel7a-header"
                    >
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10300").toUpperCase()}</u>
                        </p>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <CompanyScopes
                          allCategories={allCategories}
                          allScopes={allUserScopes}
                          scopes={userScopes}
                          setScopes={setUserScopes}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            isSaving={isSaving}
            disabled={disableSave()}
          />

          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardContent>
      </Card>
    </Fragment>
  )
}

// Griglia interna alla finestra
export default function Company(props) {
  const { adminTableRowData, creation } = props
  const { t } = useContext(RootContext)

  /*const useStyles = makeStyles((theme) => ({
    progress: {
       margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()*/

  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return t("10310")
    // 2023/01/04 - Loading non funziona quando fà fetchCompany 
    //return <CircularProgress className={classes.progress} />
  }
}
