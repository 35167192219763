//Crea un pannello per la scelta della company sulla quale lavorare
import React, { useContext, useState, useEffect, useCallback, useRef } from "react"
import { RootContext } from "../../RootContext"

//Material UI
import { makeStyles } from '@mui/styles';
import TextField from "@mui/material/TextField"
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import Typography from "@mui/material/Typography"


//Componenti Pegaso
import { CircularIndeterminate } from "../../views/Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default function FilterCompany(props) {
  const {
    urlAccessCode,
    setUrlAccessCode,
    setCompanyName,
    setCompanyId,
    setChangeCompany,
    setCompanyDeposits,
    setCompanyLanguage,
    setCompanyScopes,
    setUserScopes,
  } = props

  const {
    allFilterCompanies,
    t,
    access_token,
    refresh_token,
    setAccess_token,
    apiUrl,
  } = useContext(RootContext)

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const abortController = useRef(null)

  const [companyUrl, setCompanyUrl] = useState()

  useEffect(() => {
    if (urlAccessCode) {
      setUrlAccessCode(urlAccessCode)
    }
  }, [
    urlAccessCode,
    setUrlAccessCode,
    setChangeCompany,
  ])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const fetchActiveUserScopes = useCallback((newUrlAccessCode) => {
    abortController.current = new AbortController()
    const url = `${apiUrl}/UserActiveScope/${newUrlAccessCode}/get-all`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
      signal: abortController.current.signal,
    })
      .then((response) => { return response.body})
      .then((data) => {
        setUserScopes(data)
      })
      .catch((err) => {
        if (err.message.includes("abort") < 0) {
        }
        ErrorUtils.errorLog(`${url} => fetchActiveUserScopes`, err.message)
      })
  }, [
    access_token,
    apiUrl,

    refresh_token,
    setAccess_token,
  ])

  const fetchActiveCompanyScopes = useCallback((newUrlAccessCode) => {
    abortController.current = new AbortController()
    const url = `${apiUrl}/CompanyActiveScope/${newUrlAccessCode}/get-all`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
      signal: abortController.current.signal,
    })
      .then((response) => { return response.body})
      .then((data) => {
        setCompanyScopes(data)
      })
      .catch((err) => {
        if (err.message.includes("abort") < 0) {
        }
        ErrorUtils.errorLog(`${url} => fetchActiveUserScopes`, err.message)
      })
  }, [
    access_token,
    apiUrl,
    refresh_token,
    setAccess_token,
  ])

  const fetchCompanyDeposits = useCallback((newUrlAccessCode) => {
      abortController.current = new AbortController()
      const url = `${apiUrl}/Deposit/get-all?urlAccessCode=${newUrlAccessCode}`
      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
      })
        .then((response) => { return response.body})
        .then((data) => {
          setCompanyDeposits(data)
        })
        .catch((err) => {
          ErrorUtils.errorLog(`${url} => fetchCompanyDeposits`, err)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token]
  )

  return !allFilterCompanies ? (
    <div className={classes.root}>
      <List component="nav" aria-label="Device settings">
        <ListItem>
          <ListItemText primary={t("10275").toUpperCase()} />
          <CircularIndeterminate />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      ></Menu>
    </div>
  ) : (
    <div>
      <Card>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Autocomplete
                id="filterCompany"
                style={{ width: 400 }}
                disableClearable={true}
                options={allFilterCompanies}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.companyJobd + " - " + option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("10042").toUpperCase()}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, newValue) => {
                  const hostName = window.location.hostname
                  if (
                    hostName.indexOf("localhost") >= 0 ||
                    hostName.indexOf("pre-tel2") >= 0
                  ) {
                    setCompanyUrl(
                      //"https://pre-tel2.pegasoplatform.com/#/" + newValue.urlAccessCode
                      'https://pre-pegaso-servizi.it/NewGrafica/?user=' + newValue.urlAccessCode
                    )
                  } else {
                    setCompanyUrl(
                      //"https://tel2.pegasoplatform.com/#/" + newValue.urlAccessCode
                      'https://www.pegaso-servizi.com/NewGrafica/?user=' + newValue.urlAccessCode
                    )
                  }
                  setChangeCompany(true)
                  setUrlAccessCode(newValue.urlAccessCode)
                  if (setCompanyScopes) {
                    setCompanyScopes(newValue.companyScopes)
                  }
                  setCompanyName(newValue.name)
                  setCompanyId(newValue.companyId)
                  if (setCompanyLanguage) {
                    setCompanyLanguage(newValue.language)
                  }
                  setAnchorEl(null)
                  fetchActiveUserScopes(newValue.urlAccessCode)
                  fetchActiveCompanyScopes(newValue.urlAccessCode)
                  fetchCompanyDeposits(newValue.urlAccessCode)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {urlAccessCode ? (
                <Typography>
                  {t("10118") + ": "}
                  <Link
                    //href="#"
                    onClick={() => {
                      window.open(companyUrl, "_blank")
                    }}
                    variant="body2"
                    style={{ cursor: 'pointer' }}
                  >
                    {companyUrl}
                  </Link>
                </Typography>
              ) :
                null}
            </Grid>
          </Grid>
        </div>
      </Card>
    </div>
  )
}
